
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { listMixin } from "@/mixins/listMixin";
import { mixins } from "vue-class-component";
import QAndA from "../components/qAndA.vue";
import { GetZiliaoList } from "@/request/manage";
@Component({
  components: {
    QAndA,
  },
})
export default class Name extends mixins(listMixin) {
  private data: any[] = [];
  private cState: string = "资料下载";
  private gongzuozhanUrl: string =
    "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/docs/%E5%B2%90%E9%BB%84%E6%95%B0%E6%8D%AEAI%E5%B7%A5%E4%BD%9C%E7%AB%99%E4%BA%A7%E5%93%81%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E%E6%96%87%E6%A1%A3%EF%BC%88V1.0%EF%BC%89.pdf";
  private shenqing: string =
    "https://zydata.oss-cn-shanghai.aliyuncs.com/ds/docs/%E6%9C%BA%E6%9E%84%E7%94%A8%E6%88%B7%E5%85%8D%E8%B4%B9%E4%BD%BF%E7%94%A8%E7%94%B3%E8%AF%B7%E8%A1%A8.pdf";
  private stateChange(state: any) {
    this.cState = state;
  }
  private downloadGongzuozhan() {
    window.open(this.gongzuozhanUrl);
  }
  private downloadShenqing() {
    window.open(this.shenqing);
  }
  private openUrl(url: any) {
    window.open(url);
  }
  /**
   * @description 获取列表
   */
  getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        order_value: this.filters.sort_value,
        order_field: "创建时间",
      },
    };
    GetZiliaoList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.getList();
  }
}
