



















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import CommonHelp from "../components/common-help.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    CommonHelp,
  },
})
export default class Name extends Vue {}
